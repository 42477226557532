import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { usersOnboardingService } from "../../../../../Services/usersOnboarding";

const GradientDonutAdminClientChart = () => {
  const permission = JSON.parse(localStorage.getItem("permission"));

  const [activeClients, setActiveClients] = useState(0);
  const [inactiveClients, setInactiveClients] = useState(0);
  const [freezedClients, setFreezedClients] = useState(0);
  const [archivedClients, setArchivedClients] = useState(0);
  const [onboardClients, setOnboardClients] = useState(0);
  const countOfRoleStatus = async (e) => {
    let data = await usersOnboardingService
      .getSeparateRoleStatusCount(permission)
      .then((res) => res?.RoleStatus?.[0])
      .catch((err) => console.log(err));
    const totalActiveClients = data && (data?.totalActiveClients ?? 0);
    const totalApprovedClients = data && (data?.totalApprovedClients ?? 0);
    const totalFreezeClients = data && (data?.totalFreezeClients ?? 0);
    const totalInactiveClients = data && (data?.totalInactiveClients ?? 0);
    const totalArchiveClients = data && (data?.totalArchiveClients ?? 0);
    const totalOnBoardingClients = data && (data?.totalOnBoardingClients ?? 0);
    setActiveClients(totalActiveClients + totalApprovedClients);
    setFreezedClients(totalFreezeClients);
    setInactiveClients(totalInactiveClients);
    setArchivedClients(totalArchiveClients);
    setOnboardClients(totalOnBoardingClients);
    setClientGraph([
      totalActiveClients + totalApprovedClients,
      totalInactiveClients,
      totalFreezeClients,
      totalArchiveClients,
      totalOnBoardingClients,
    ]);
  };
  const [clientsGraph, setClientGraph] = useState([
    activeClients,
    inactiveClients,
    freezedClients,
    archivedClients,
    onboardClients,
  ]);

  useEffect(() => {
    countOfRoleStatus();
  }, []);

  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: "Client Data",
    },
    labels: ["Active", "Inactive", "Freeze", "Archived", "Onboarding"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    colors: ["#50c150", "#e94e51", "#ddaf00", "#808080", "#1717d8"],
  };

  return (
    <div id="chart" className="chart_design_port">
      <ReactApexChart
        options={options}
        series={clientsGraph}
        type="donut"
        width={360}
      />
    </div>
  );
};

export default GradientDonutAdminClientChart;
