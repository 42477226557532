import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Dropdown } from "react-bootstrap";
import "../../Styles/Navbar.scss";
import profile from "../image/navbar/profile.png";
import client1 from "../image/navbar/client1.png";
import { userServices } from "../../Services/userServices";
import { studyOnboardingService } from "../../Services/studyServices/studyOnboarding";
import Profilers from "./Profilers";
import Profilers1 from "./Profilers1";
import ProfileActions from "./ProfileActions";
import { BarsIcon } from "../../Assets/Icons/GlobalIcons";
import BottomFilebar from "./BottomFilebar";
import SignOut from "./SignOut";
import SwitchAccess from "./SwitchAccess";

const Navbar = ({
  Profiler,
  Profiler1,
  RemoveDropdowns,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  Tools_Library,
  Study_Tools,
  ToolsItem,
  FormDrop_GlobalForm,
  FormDrop_ClientLevelForm,
  FormDrop_ProjectLevelForm,
}) => {
  const { studyId } = useParams();

  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [clientID, setClientID] = useState([]);
  const [projectID, setProjectID] = useState([]);
  const [projectLabel, setProjectLabel] = useState("");
  const [projectMode, setProjectMode] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [managementRole, setManagementRole] = useState("");

  const [image, setImage] = useState("");

  const getCurrentTimeZone = () => {
    const now = new Date();
    const timeZoneOffsetMinutes = now.getTimezoneOffset();
    const timeZoneOffsetHours = Math.abs(
      Math.floor(timeZoneOffsetMinutes / 60)
    );
    const timeZoneOffsetMinutesRemainder = Math.abs(timeZoneOffsetMinutes % 60);
    const timeZoneOffsetSign = timeZoneOffsetMinutes >= 0 ? "-" : "+";
    const formattedTimeZone = `GMT${timeZoneOffsetSign}${String(
      timeZoneOffsetHours
    ).padStart(2, "0")}:${String(timeZoneOffsetMinutesRemainder).padStart(
      2,
      "0"
    )}`;
    setTimeZone(formattedTimeZone);
  };

  useEffect(() => {
    profileData();
    getCurrentTimeZone();
  }, []);

  const profileData = async () => {
    const item1 = JSON.parse(localStorage.getItem("firstName"));
    const item2 = JSON.parse(localStorage.getItem("lastName"));

    if (item1) {
      setFirstName(item1);
    }

    if (item2) {
      setLastName(item2);
    }
  };

  const studyLogout = JSON.parse(localStorage.getItem("role"));
  const studyManagement = JSON.parse(localStorage.getItem("studyManagement"));

  const logout = async () => {
    await userServices.logout();
  };

  const bottomFilebarProps = {
    logout,
    Tools_Library,
    Study_Tools,
    ToolsItem,
    RemoveDropdowns,
    Dropdown1,
    Dropdown2,
    Dropdown3,
    Dropdown4,
    Dropdown5,
    Dropdown6,
    FormDrop_GlobalForm,
    FormDrop_ClientLevelForm,
    FormDrop_ProjectLevelForm,
  };

  useEffect(() => {
    const getProjectIdByStudyId = async () => {
      let projectId = await studyOnboardingService.getProjectIdByStudyId(
        studyId
      );
      setProjectID(projectId?.data?.project_id);
      setProjectLabel(projectId?.data?.projectLabel);
    };

    const currentDetails = async (e) => {
      let userData = await userServices.getCurrentUserDetails(e);
      setClientID(userData?.[0]?.user_id?.userId);
      setManagementRole(userData?.[1]?.management_name?.name);
      setImage(userData?.[0]?.user_id?.image);
    };

    currentDetails();
    getProjectIdByStudyId();
  }, [studyId]);

  return (
    <>
      <nav className="navbar navbar-expand-lg edc-nav p-0">
        <div className="navcontainer-fluid d-block">
          <div className="hstack justify-content-between">
            <h5 className="navbar-brand white mr-nav">Galax®</h5>
            <form role="search" className="navSearchContainer">
              <ul className="d-block justify-content-between align-items-baseline list-unstyled mb-0 d-sm-flex display">
                <li className="nav-item border-0">
                  <div className="nav-item dropdown m-0 border-0">
                    <span className="white login adminLoggedIn border-0 bg-transparent">
                      <i className="fa-solid fa-earth-asia me-1 text-info"></i>
                      Timezone Timezone : {timeZone}
                    </span>
                  </div>
                </li>

                <li className="nav-item border-0">
                  <div className="nav-item dropdown m-0 border-0">
                    <span className="white login adminLoggedIn border-0 bg-transparent">
                      <i className="fa-solid fa-building-user me-1 text-info"></i>
                      Logged in as :{" "}
                      {studyLogout === "super_user"
                        ? "Super Admin"
                        : studyLogout === "devops"
                        ? "Devops"
                        : "User"}
                    </span>
                  </div>
                </li>

                <li className="nav-item d-lg-block">
                  <ProfileActions
                    ProfileImage={image}
                    firstName={firstName}
                    lastName={lastName}
                  />
                </li>
                <li className="nav-item d-none d-lg-block m-0">
                  <SignOut logout={logout} />
                </li>
              </ul>
            </form>
          </div>
          <hr className="m-0" />
          <div className="hstack justify-content-between filebar">
            <div className="filebars">
              <div className="bottomFilebar">
                <Dropdown>
                  <Dropdown.Toggle className="white bell" variant="">
                    <BarsIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <BottomFilebar {...bottomFilebarProps} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-none d-lg-block">
                <BottomFilebar {...bottomFilebarProps} />
              </div>
            </div>

            {Profiler && (
              <Profilers
                profile={profile}
                client1={client1}
                projectid={projectLabel}
              />
            )}

            {Profiler1 && (
              <Profilers1
                projectID={projectLabel}
                clientID={clientID}
                projectMode={projectMode}
              />
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
