import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const studyOnboardingService = {
  createStudyVisitColumn,
  getStudyVisitAssignedForm,
  createStudyVisitColumnBySubject,
  updateStudyVisitColumn,
  getStudyVisitColumnData,
  getStudyVisitColumnDataBySubject,
  getdataEntryFormById,
  AddStudyVisitRow,
  AddStudyVisitRowBySubject,
  reviewStudyVisit,
  getStudyVisitReviewData,
  UpdateStudyVisitRow,
  DuplicateStudyVisitRow,
  getStudyVisitRowData,
  getStudyVisitRowDataBySubject,
  deleteStudyVisitRowData,
  getStudyVisitAttributeByID,
  getStudyVisitHistoryByID,
  exportStudyVisitDataInMultiFormat,
  exportStudyVisitsAuditTrialDataInMultiFormat,
  getStudyVisitAttributes,
  getInitiateVisitsDataEntryFormByStudyId,
  getTablePropertyData
};

async function getTablePropertyData(category, columnId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTablePropertyData/${category}/${columnId}/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function createStudyVisitColumn(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createStudyVisitColumn`,
    headersWithAuth("POST", datas, headers)
    );
  const data = await response.json();
  await handleResponse(data);
}

async function createStudyVisitColumnBySubject(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createStudyVisitColumnBySubject`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function reviewStudyVisit(datas, endPoint) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}${endPoint}`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function getStudyVisitReviewData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitReviewData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitAssignedForm(studyId, studyUniqueId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitAssignedForm/${studyId, studyUniqueId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
 
async function getStudyVisitAttributes(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitAttributes`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
 
async function getInitiateVisitsDataEntryFormByStudyId(formUniqueId, studyUniqueId, studyMode, studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getInitiateVisitsDataEntryFormByStudyId/${formUniqueId}/${studyUniqueId}/${studyMode}/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
 
async function getdataEntryFormById(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getdataEntryFormById/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitColumnData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitColumnData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitColumnDataBySubject(studyId, subjectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitColumnDataBySubject/${studyId}/${subjectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function AddStudyVisitRow(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}AddStudyVisitRow`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function AddStudyVisitRowBySubject(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}AddStudyVisitRowBySubject`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getStudyVisitRowData(studyId, columnId, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitRowData/${studyId}/${columnId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitRowDataBySubject(studyId,subjectId, studyMode, columnId, page, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitRowDataBySubject/${studyId}/${subjectId}/${studyMode}/${columnId}/${page}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateStudyVisitColumn(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStudyVisitColumn/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function UpdateStudyVisitRow(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}UpdateStudyVisitRow/${id}`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function DuplicateStudyVisitRow(id, userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}DuplicateStudyVisitRow/${id}`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteStudyVisitRowData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyVisitRowData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getStudyVisitAttributeByID(id, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitAttributeByID/${id}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyVisitHistoryByID(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyVisitHistoryByID/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function exportStudyVisitDataInMultiFormat(studyId, extension) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportStudyVisitDataInMultiFormat/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}

async function exportStudyVisitsAuditTrialDataInMultiFormat(
  studyId,
  extension
) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportStudyVisitsAuditTrialDataInMultiFormat/${studyId}/${extension}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
