import React from "react";

const CopyRight = () => (
  <div className="mt-3">
    <a
      target="_blank"
      href="https://www.galaxbiotech.com/"
      className="galaxsite text-center d-flex align-items-center justify-content-center pb-2"
    >
      www.galaxbiotech.com
    </a>

    <p className="copyright text-center m-0">
      Copyright© 2023 Galaxbiotech All Rights Reserved
    </p>
    <br />
    <p className="text-center copyright d-flex">
      <a
        href="#"
        className="galaxsite text-center d-flex align-items-center justify-content-center pb-2"
      >
        Terms of Use & Legal Information
      </a>
      &nbsp;&nbsp; | &nbsp;&nbsp;
      <a
        href="#"
        className="galaxsite text-center d-flex align-items-center justify-content-center pb-2"
      >
        Privacy Statement
      </a>
    </p>
  </div>
);

export default CopyRight;
