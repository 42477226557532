import { useCallback, useState } from "react";
import { studyOnboardingService } from "./../../../../Services/studyServices/studyVisitsServices";
import moment from "moment";
import UseToggle from "../../../../Hooks/UseToggle";
import { v4 as uuidv4 } from "uuid";

export const UseAppendInput = (studyDynamicColumn, setStudyDynamicColumn) => {
  const [formValues0, setFormValues0] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [formValues1, setFormValues1] = useState([]);
  const [formValues2, setFormValues2] = useState([]);
  const [formValues21, setFormValues21] = useState([]);
  const [formValues3, setFormValues3] = useState([]);
  const [formValues31, setFormValues31] = useState([]);
  const [formValues4, setFormValues4] = useState([]);
  const [formValues5, setFormValues5] = useState([]);
  const [formValues6, setFormValues6] = useState([]);
  let randomNumber = Math.random().toString().substr(2, 10);

  const handleChange0 = useCallback((e, i) => {
    const { name, value, checked } = e.target;

    setFormValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields0 = (type) => {
    setFormValues([...formValues, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields0 = (i) => {
    setFormValues((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ..........................................................................

  const handleChange = useCallback((e, i) => {
    const { name, value, checked } = e.target;

    setFormValues((prevValues) => {
      const newValues = [...prevValues];
      // newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      // newValues['text'][name] = value || checked;
      return newValues;
    });
  }, []);

  const addFormFields = (type) => {
    setFormValues([...formValues, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields = (i) => {
    setFormValues((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ..........................................................................

  const handleChange1 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues1((prevValues) => {
      const newValues = [...prevValues];
      // newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields1 = (type) => {
    setFormValues1([...formValues1, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields1 = (i) => {
    setFormValues1((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................

  // ..........................................................................

  const handleChange2 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues2((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields2 = (type) => {
    setFormValues2([...formValues2, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields2 = (i) => {
    setFormValues2((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................

  // ..........................................................................

  const handleChange21 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues21((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields21 = (type) => {
    setFormValues21([...formValues21, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields21 = (i) => {
    setFormValues21((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................

  // ..........................................................................

  const handleChange3 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues3((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields3 = (type) => {
    setFormValues3([...formValues3, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields3 = (i) => {
    setFormValues3((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................

  // ..........................................................................

  const handleChange31 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues31((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, []);

  const addFormFields31 = (type) => {
    setFormValues31([...formValues31, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields31 = (i) => {
    setFormValues31((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................

  // ..........................................................................

  // const handleChange4 = useCallback((e, i) => {
  //   const { name, value } = e.target;

  //   setFormValues4((prevValues) => {
  //     const newValues = [...prevValues];
  //     // newValues[i] = { ...newValues[i], [name]: value };
  //     newValues[i][name] = value;
  //     return newValues;
  //   });
  // }, []);
  const handleChange4 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues4((prevValues) => {
      const newValues = [...prevValues];
      newValues[i] = { ...newValues[i], [name]: value };
      return newValues;
    });
  }, [setFormValues4]);


  const addFormFields4 = (type) => {
    setFormValues4([...formValues4, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields4 = (i) => {
    setFormValues4((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................
  // ............................................................................

  const handleChange5 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues5((prevValues) => {
      const newValues = [...prevValues];
      // newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, [setFormValues5]);

  const addFormFields5 = (type) => {
    setFormValues5([...formValues5, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields5 = (i) => {
    setFormValues5((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................
  // ..........................................................................

  const handleChange6 = useCallback((e, i) => {
    const { name, value } = e.target;

    setFormValues6((prevValues) => {
      const newValues = [...prevValues];
      // newValues[i] = { ...newValues[i], [name]: value };
      newValues[i][name] = value;
      return newValues;
    });
  }, [setFormValues6]);

  const addFormFields6 = (type) => {
    setFormValues6([...formValues6, { type: type, uniqueId: randomNumber }]);
  }

  const removeFormFields6 = (i) => {
    setFormValues6((prevValues) => {
      let newFormValues = [...prevValues];
      newFormValues.splice(i, 1);
      return newFormValues;
    });
  };

  // ............................................................................

  const onChangeInput = (e, i) => {
    const { name, value } = e.target;
    let items1 = [...studyDynamicColumn];
    items1[i][name] = value;

    setStudyDynamicColumn(items1);
  };

  return {
    formValues0,
    handleChange0,
    addFormFields0,
    removeFormFields0,
    setFormValues0,

    formValues,
    handleChange,
    addFormFields,
    removeFormFields,
    setFormValues,

    formValues1,
    handleChange1,
    addFormFields1,
    removeFormFields1,
    setFormValues1,

    formValues2,
    handleChange2,
    addFormFields2,
    removeFormFields2,
    setFormValues2,

    formValues21,
    handleChange21,
    addFormFields21,
    removeFormFields21,
    setFormValues21,

    formValues3,
    handleChange3,
    addFormFields3,
    removeFormFields3,
    setFormValues3,

    formValues31,
    handleChange31,
    addFormFields31,
    removeFormFields31,
    setFormValues31,

    formValues4,
    handleChange4,
    addFormFields4,
    removeFormFields4,
    setFormValues4,

    formValues5,
    handleChange5,
    addFormFields5,
    removeFormFields5,
    setFormValues5,

    formValues6,
    handleChange6,
    addFormFields6,
    removeFormFields6,
    setFormValues6,

    onChangeInput,


  };
};

export const UseUpdateColumn = (studyDynamicColumn, setStudyDynamicColumn) => {
  const addUpdateOnChange = (e, i) => {
    const { name, value } = e.target;

    let newValues = [...studyDynamicColumn];
    newValues[i][name] = value;

    setStudyDynamicColumn(newValues);
  };

  const addUpdateFields = () =>
    setStudyDynamicColumn([...studyDynamicColumn, {}]);

  const removeAddUpdateFields = (i) => {
    let newFormValues = [...studyDynamicColumn];
    newFormValues.splice(i, 1);

    setStudyDynamicColumn(newFormValues);
  };

  return { addUpdateOnChange, addUpdateFields, removeAddUpdateFields };
};

export const UseSignature = (endPoint, connectUniqueIdData) => {

  const SignFormDetails = {
    userId: "",
    password: "",
    review: "",
    status: "",
  };

  const reviewIdDetails = {
    uniqueId: "",
    study_id: "",
    project_id: "",
    studyUniqueId: "",
    formFieldId: "",
  };

  const { toggle4: signModal, setToggle4: setSignModal } = UseToggle();

  const [signForm, setSignForm] = useState(SignFormDetails);
  const [reviewIds, setReviewIds] = useState(reviewIdDetails);

  const onValueChange = (e, type) =>
    setSignForm({ ...signForm, [type]: e.target.value });

  const setIdReviewRowTable = (uniqueId, study_id, project_id, studyUniqueId, formFieldId) => {
    setSignModal();
    setReviewIds({ ...reviewIds, uniqueId, study_id, project_id, studyUniqueId, formFieldId});
  };

  const studyReview = async (e) => {
    e.preventDefault();
    let data = {
      ...signForm,
      ...reviewIds,
      connectUniqueIdData : connectUniqueIdData
    };

    const res = await studyOnboardingService.reviewStudyVisit(data, endPoint);
    if (res?.statusCode === 200) {
      setSignForm(SignFormDetails);
      setReviewIds(reviewIdDetails);
    }
  };

  const signFormProps = () => ({
    SHOW: !signModal,
    HIDE: setSignModal,
    Title: "Signature Required",
    UpdateRow: "UpdateRow",
    type2: "password",
    Label1: "Username",
    Label2: "Password",
    Radio: "Radio",
    Value1: signForm?.userId,
    Value2: signForm?.password,
    OnChange1: (e) => onValueChange(e, "userId"),
    OnChange2: (e) => onValueChange(e, "password"),
    RadioOnChange: (e) => onValueChange(e, "status"),
    RadioChecked1: signForm?.status === "varified",
    RadioChecked2: signForm?.status === "pending",
    CommentValue: signForm?.review,
    CommentOnChange: (e) => onValueChange(e, "review"),
    SubmitTitle: "Submit",
    SubmitForm: (e) => studyReview(e),
  });

  return {
    signForm,
    setIdReviewRowTable,
    onValueChange,
    setSignModal,
    signFormProps,
  };
};

export const UseAttributeTable = () => {
  // GET ATTRIBUTE TABLE DATA.....................................................

  const attributeFormDetails = {
    attributeDetails: [],
    uploadedBy: [],
    jobCreatedDateTime: [],
    version: [],
    reviewedBy: [],
    reviewUpdatedDateTime: [],
  };

  const [attributeForm, setAttributeForm] = useState(attributeFormDetails);
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const getStudyVisitAttributeByID = async (e) => {

    let data = await studyOnboardingService.getStudyVisitAttributeByID(e, studyMode);

    setAttributeForm({
      ...attributeForm,
      attributeDetails: data?.data,
      uploadedBy: data?.data?.[0]?.uploadedBy,

      jobCreatedDateTime: moment(data?.data?.[0]?.createdAt).format(
        "DD-MM-YYYY | HH:mm"
      ),

      version: data?.data?.[0]?.version,
      reviewedBy: data?.data?.[0]?.reviewedBy,
      status: data?.data?.[0]?.status,

      reviewUpdatedDateTime: moment(data?.data?.[0]?.updatedAt).format(
        "DD-MM-YYYY | HH:mm"
      ),
    });
  };

  return { attributeForm, getStudyVisitAttributeByID };
};

export const UseHistoryTable = () => {
  const [studyVisitHistoryLists, setStudyVisitHistoryLists] = useState([]);

  // GET HISTORY TABLE DATA?.......................................................

  const getStudyVisitHistoryByID = async (e) => {
    let data = await studyOnboardingService.getStudyVisitHistoryByID(e);
    let initialData = data?.data;
    let finalData = initialData?.sort()?.reverse();
    setStudyVisitHistoryLists(finalData);
  };

  return { studyVisitHistoryLists, getStudyVisitHistoryByID };
};
