import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { usersOnboardingService } from "../../../../../Services/usersOnboarding";

const GradientDonutClientChart = () => {
  const [activeClients, setActiveClients] = useState(0);
  const [inactiveClients, setInactiveClients] = useState(0);
  const [freezedClients, setFreezedClients] = useState(0);
  const [archivedClients, setArchivedClients] = useState(0);
  const [clientsGraph, setClientGraph] = useState([activeClients, inactiveClients, freezedClients, archivedClients])

  const getSeparateAssignRoleStatusCount = async (e) => {
    let data = await usersOnboardingService.getSeparateAssignRoleStatusCount(e);
    setActiveClients(data.totalActive);
    setFreezedClients(
      data.totalFreezed
    );
    setInactiveClients(
      data.totalInactive);
    setArchivedClients(
      data.totalArchived
    );
    setClientGraph([data.totalActive, data.totalFreezed, data.totalInactive, data.totalArchived])
  };

  useEffect(() => {
    getSeparateAssignRoleStatusCount();
  }, []);

  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: 'Client Data',
    },
    labels: ["Active", "Inactive", "Freeze", "Archived"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    colors: ["#50c150", "#e94e51", "#ddaf00", "#808080"]
  };
  
  return (
    <div id="chart" className="chart_design_port">
      <ReactApexChart
        options={options}
        series={clientsGraph}
        type="donut"
        width={360}
      />
    </div>
  );
  
}

export default GradientDonutClientChart;
