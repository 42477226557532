import React, { useState } from "react";
import { userServices } from "../../Services/userServices";
import EyeIcon, { EyeSlashIcon } from "../../Assets/Svg/EyeIcon";
import { Button, Form, Modal } from "react-bootstrap";
import UseToggle from "../../Hooks/UseToggle";
const ChangePassword = ({ SHOW, HIDE, TITLE }) => {
  const {
    toggle,
    setToggle,
    toggle1,
    setToggle1,
    toggle2,
    setToggle2,
    togglePassword,
  } = UseToggle();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    let userData = {};
    userData.currentPassword = oldPassword;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;

    await userServices.changePassword(userData);
    HIDE();
  };

  const [error, setError] = useState("");

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };
  return (
    <Modal show={SHOW} onHide={HIDE}>
      <Modal.Header closeButton>
        <Modal.Title> {TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="password passwordSection">
          <Form.Group>
            <Form.Label className="col-form-label">
              Current Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type={togglePassword(toggle)}
              placeholder="Enter your Current Password"
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
            />
            <span className="passwordIcon CP" onClick={setToggle}>
              {toggle ? <EyeIcon /> : <EyeSlashIcon />}
            </span>
          </Form.Group>
        </div>
        <div className="password passwordSection">
          <Form.Group>
            <Form.Label className="col-form-label">
              New Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type={togglePassword(toggle1)}
              placeholder="Enter New Password"
              onChange={(e) => {
                setNewPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              value={newPassword}
            />
            <span className="passwordIcon CP" onClick={setToggle1}>
              {toggle1 ? <EyeIcon /> : <EyeSlashIcon />}
            </span>
          </Form.Group>
        </div>
        {error && (
          <p
            className="m-1"
            style={{ color: "red", fontSize: 11, lineHeight: "15px" }}
          >
            {error}
          </p>
        )}
        <div className="password passwordSection">
          <Form.Group>
            <Form.Label className="col-form-label">
              ConFirm Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type={togglePassword(toggle2)}
              placeholder="Confirm Password"
              onChange={(e) => setRepeatPassword(e.target.value)}
              value={repeatPassword}
            />
            <span className="passwordIcon CP" onClick={setToggle2}>
              {toggle2 ? <EyeIcon /> : <EyeSlashIcon />}
            </span>
          </Form.Group>
        </div>
        {newPassword === repeatPassword ? (
          ""
        ) : (
          <p className="mt-1" style={{ color: "red", fontSize: "11px" }}>
            {"Repeat Password not match from new password"}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="secondary"
          onClick={HIDE}
          className="btn submitbutton me-2"
          style={{ padding: "3px 20px 3px" }}
        >
          Cancel
        </button>
        {error ||
        newPassword != repeatPassword ||
        newPassword?.length === 0 ||
        oldPassword?.length === 0 ? (
          ""
        ) : (
          <button
            className="btn submitbutton me-2"
            onClick={handleSubmit}
            style={{ padding: "3px 20px 3px" }}
          >
            Update
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePassword;
