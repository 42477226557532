import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { NoDataFoundTable } from "../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";

const AttributesClientTable = ({ clientDetails }) => {
  
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {clientDetails ? (
          <Table
            id="resizable-table"
            striped
            hover
            className="m-0 custom-table"
          >
            <thead className="position-sticky z-1 top-0">
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sub Domain</td>
                <td className="">
                  <a
                    target="_blank"
                    href={`https://${clientDetails?.shortName}.galaxbiotech.com`}
                  >
                    {`${clientDetails?.shortName}.galaxbiotech.com`}
                  </a>
                </td>
              </tr>
              <tr>
                <td>DB Host</td>
                <td>{clientDetails?.db_host}</td>
              </tr>
              <tr>
                <td>DB Name</td>
                <td className="">{clientDetails?.db_name}</td>
              </tr>
              <tr>
                <td>DB User</td>
                <td>{clientDetails?.db_user}</td>
              </tr>
              <tr>
                <td>DB Password</td>
                <td>{clientDetails?.db_password}</td>
              </tr>
              <tr>
                <td>Registered Date | Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm" withTitle>
                    {clientDetails?.createdAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <NoDataFoundTable MSG="Properties Not Found!" />
        )}
      </SplitPane>
    </>
  );
};

export default AttributesClientTable;
