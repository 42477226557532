import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { usersOnboardingService } from "../../../../../Services/usersOnboarding";

const GradientDonutStudy = ({ userType }) => {
  const [studyStatus, setStudyStatus] = useState([]);

  let endPoint =
    userType === "client"
      ? usersOnboardingService.getUserStudyStatus()
      : usersOnboardingService.getStudyStatus();
  const getUserStudyStatus = async (e) => {
    let data = await endPoint;
    setStudyStatus(data?.data);
  };

  useEffect(() => {
    getUserStudyStatus();
  }, []);

  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: "Study Data",
    },
    labels: ["Active", "Inactive", "Freeze", "Locked", "Completed", "Pending"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    colors: ["#50c150", "#e94e51", "#ddaf00", "#808080", "#008FFB", "#546E7A"], // Custom colors for the series
  };

  return (
    <div id="chart" className="chart_design_port">
      <ReactApexChart
        options={options}
        series={studyStatus}
        type="donut"
        width={380}
      />
    </div>
  );
};

export default GradientDonutStudy;
