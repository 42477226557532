import React, { useEffect, useState } from "react";
import { userServices } from "../../../Services/userServices";
const TwoFactorAuthByApps = ({ appTwoFA, getTwoFactorAuthType }) => {
  const [base64, setBase64] = useState("")
  const [secret, setSecret] = useState("")
  const [check, setCheck] = useState(false)
  const [disable, setDisable] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [OTP, setOTP] = useState("")

  const generateQRCode = async () => {
    let data = await userServices.generateQRCode()
    setBase64(data.base64)
    setSecret(data.secret)
  }

  const showQRCode = async () => {
    let data = await userServices.showQRCode()
    setBase64(data)
    if (data) {
      setShowQR(true)
    }
  }

  const checkQRCode = async () => {
    let userData = {}
    userData.secret = secret
    userData.otp = OTP
    let data = await userServices.checkQRCode(userData)
    if (data?.statusCode === 200) {
      setOTP("")
    }
  }

  const enableTwoFactorAuth = async () => {
    let userData = {}
    userData.secret = secret
    userData.otp = OTP
    let data = await userServices.enableTwoFactorAuth(userData)
    if (data?.statusCode === 200) {
      getTwoFactorAuthType()
      setOTP("")
    }
  }

  const disableTwoFactorAuth = async () => {
    let userData = {}
    userData.secret = secret
    userData.otp = OTP
    let data = await userServices.disableTwoFactorAuth(userData)
    if (data?.statusCode === 200) {
      getTwoFactorAuthType()
      setDisable(false)
      setShowQR(false)
      setCheck(false)
      setBase64("")
      setSecret("")
    }
  }

  const getQRDataForDisable = async () => {
    let data = await userServices.getQRData()
    setSecret(data)
    setDisable(true)
  }
  return (
    <>
      {!disable ?
        <>
          {!appTwoFA ?
            <>
              {!check ?
                <>
                  <div className="qr__imag">
                    {base64 ?
                      <img src={base64} />
                      :
                      <div>
                        <div className="twoFactor">
                          <i class="fa-solid fa-shield-halved red"></i>
                        </div>
                        <div className="twoFATitle">Disabled</div>
                      </div>
                    }
                  </div>
                  <div className="hstack gap-3 justify-content-end pt-3">
                    <button className="mt-2 updateProfile" type="button" onClick={() => generateQRCode()}>{base64 ? "Re-Generate QR" : "Generate QR"}</button>
                    {base64 &&
                      <button className="mt-2 updateProfile" type="button" onClick={() => setCheck(true)}>Next</button>
                    }
                  </div>
                </>
                :
                <>
                  <label className="small mb-1" for="inputCountry">
                    Enter OTP
                  </label>
                  <input
                    type="number"
                    className="form-control inputborder inputsize w-100"
                    onChange={(e) => setOTP(e.target.value)}
                    value={OTP}
                    required
                    placeholder="Enter OTP" />
                  <div className="hstack gap-3 justify-content-end pt-3 footer_button">
                    <button className="mt-2 updateProfile" type="button" onClick={() => checkQRCode()}>Verify</button>
                    <button className="mt-2 updateProfile" type="button" onClick={() => enableTwoFactorAuth()}>Verify with Enable</button>
                  </div>
                </>
              }
            </>
            :
            <>
              {!showQR ?
                <>
                  <div className="twoFactor">
                    <i class="fa-solid fa-shield-halved"></i>
                  </div>
                  <div className="twoFATitle">Enabled (2FA by Apps)</div>
                  <div className="hstack gap-3 justify-content-end pt-3 footer_button">
                    <button className="mt-2 updateProfile" type="button" onClick={() => showQRCode()}>Show QR</button>
                    <button className="mt-2 updateProfile" type="button" onClick={() => getQRDataForDisable()}>Disable</button>
                  </div>
                </>
                :
                <>
                  <div className="qr__imag">
                    {base64 &&
                      <img src={base64} />
                    }
                  </div>
                  <div className="hstack gap-3 justify-content-end pt-3 footer_button">
                    <button className="mt-2 updateProfile" type="button" onClick={() => setShowQR(false)}>Hide QR</button>
                    <button className="mt-2 updateProfile" type="button" onClick={() => getQRDataForDisable()}>Disable</button>
                  </div>
                </>
              }
            </>
          }
        </>
        :
        <>
          <label className="small mb-1" for="inputCountry">
            Enter OTP
          </label>
          <input
            type="number"
            className="form-control inputborder inputsize w-100"
            onChange={(e) => setOTP(e.target.value)}
            value={OTP}
            required
            placeholder="Enter OTP" />
          <div className="hstack gap-3 justify-content-end pt-3 footer_button">
            <button className="mt-2 updateProfile" type="button" onClick={() => { setDisable(false); setOTP("") }}>Back</button>
            <button className="mt-2 updateProfile" type="button" onClick={() => disableTwoFactorAuth()}>Disable 2FA</button>
          </div>
        </>
      }
    </>
  );
};

export default TwoFactorAuthByApps;
