import config from "../config/config.json";
import saveAs from "file-saver";
import { headers } from "./../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../Helpers/Responses";

export const usersOnboardingService = {
  getTotalServerSetupGraphData,
  getTotalUserClientDataForDevops,
  getTotalClientDataForSubadmin,
  getSeparateDevopsByStatus,
  countClientUserInvitation,
  updateUserPermission,
  getStudyStatus,
  getUserStudyStatus,
  getUserAdminStudyStatus,
  assignGroupsofManagement,
  getSeparateuserData,
  getSeparateActiveApprovedUser,
  updateUserStatusOnBoardingToActive,
  getAccessManagementPermissionBasedonId,
  getClientData,
  getSingleUserClient,
  getuserDataByID,
  createUserBySuperAdmin,
  createUserByUser,
  getAssignedClient,
  getUnAssignedClient,
  getCountries,
  getStates,
  getCities,
  getDesignation,
  deleteDesignation,
  createDesignationName,
  updateDesignationName,
  getManagentList,
  changeClientStatusByUsers,
  changeUserManagementStatus,
  assignStudyManagementGroupToUser,
  unAassignStudyManagementGroupToUser,
  changeUserStatus,
  updateApproveUnApproveStatus,
  updateUserByID,
  superUserEmail,
  getUserAdminEmailAlsoPermission,
  getClientAdminEmailAlsoPermission,
  getSingleUserRequests,
  getArchivedRequestedUsers,
  getRequestedUsers,
  countOfAllRoleStatus,
  getSeparateRoleStatusCount,
  getSeparateAssignRoleStatusCount,
  countOfArchivedRequest,
  countOfRequestWithinDays,
  assignClientofUser,
  exportuserDataInCSVFormat,
  exportuserDataInExcelFormat,
  exportActiveuserDataInCSVFormat,
  exportActiveuserDataInExcelFormat,
  exportRequestuserDataInCSVFormat,
  exportRequestuserDataInExcelFormat,
  exportArchiveRequestuserDataInCSVFormat,
  exportArchiveRequestuserDataInExcelFormat,
  getUsersUpdateHistoryData,
  assignDesignation,
  getAssignedActiveApprovedClients,
  getProjectIdBasedonClient,
  getStudyIdBasedonClient,
  getProjectIdBasedonStudyId,
  getStudyRoleBasedonStudyId,
  finadStudyLabel,
  getUserActivities,
  getTotalUserClientDataForSuperAdmin,
  getTotalInvitationGraphData,
  getStudyUserActivities,
  inviteClient,
  inviteUser,
  cancelInvitation,
  updateRequestInvitationStatus,
  deleteInvitation,
  getInvitation,
  getRequestInvitation,
  resendInvitation,
  getInvitationData,
  getTotalRequestGraphData,
};
async function updateUserPermission(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/updateUserPermission`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}
async function assignGroupsofManagement(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignGroupsofManagement`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function getSeparateuserData(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSeparateUsersByStatus/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getUserActivities() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserActivities`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function countClientUserInvitation(roleType) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}countClientUserInvitation/${roleType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyUserActivities(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyUserActivities/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTotalUserClientDataForSuperAdmin() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTotalUserClientDataForSuperAdmin`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTotalInvitationGraphData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTotalInvitationGraphData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSeparateDevopsByStatus(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSeparateDevopsByStatus/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSeparateActiveApprovedUser() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSeparateActiveApprovedUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getStudyStatus() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyStatus`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getUserStudyStatus() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserStudyStatus`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getUserAdminStudyStatus() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserAdminStudyStatus`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getArchivedRequestedUsers(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getArchivedRequestUsers/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getRequestedUsers(status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRequestUsers/${status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getuserDataByID(userID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/${userID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getClientData(userID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserClients/${userID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getAssignedClient(userID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAssignedClient/${userID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getUnAssignedClient(userID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUnAssignedClient/${userID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getAccessManagementPermissionBasedonId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAccessManagementPermissionBasedonId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function assignClientofUser(datas, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/assignClients`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteDesignation(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/deleteDesignation/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

async function getDesignation() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getDesignation`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function createDesignationName(datas, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/createDesignation`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  return data;
}
async function updateDesignationName(datas, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/updateDesignation`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  return data;
}

async function getSingleUserClient(userID, clientID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserClient/user/${userID}/client/${clientID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function createUserBySuperAdmin(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}userRegistration`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let getResponse = await handleResponse(data);
  if (getResponse === true) {
    history(`/adminRole`);
  }
}
async function superUserEmail() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}superUserEmail`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getUserAdminEmailAlsoPermission() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUserAdminEmailAlsoPermission`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getClientAdminEmailAlsoPermission() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getClientAdminEmailAlsoPermission`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function countOfAllRoleStatus() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}countOfAllRoleStatus`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getSeparateRoleStatusCount(permission) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSeparateRoleStatusCount/${permission}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getSeparateAssignRoleStatusCount() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSeparateAssignRoleStatusCount`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function countOfArchivedRequest() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}countOfRequestBeforeDays`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function countOfRequestWithinDays() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}countOfRequestWithinDays`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getSingleUserRequests(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}userRequests/forApproval/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function createUserByUser(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}request/userRegistration`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  let getResponse = await handleResponse(data);
  if (getResponse === true) {
    history(`/newuser`);
  }
  return data;
}
async function changeClientStatusByUsers(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}userClients/changeStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  // await handleResponse(data);
}

async function assignDesignation(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignDesignation`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function changeUserStatus(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/updateStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data =  await response.json();
  handleResponse(data);
}
async function updateUserStatusOnBoardingToActive(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/updateUserStatusOnBoardingToActive`,
    headersWithAuth("PUT", userData, headers)
  );
  const data =  await response.json();
  handleResponse(data);
}
async function updateApproveUnApproveStatus(userData, history) {
  await fetch(
    `${config.API_URL_USER_ONBOARDING}user/approveUnapproveUser`,
    headersWithAuth("PUT", userData, headers)
  );
}
async function updateUserByID(userData, history) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}user/updateUsersClients`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  let getResponse = await handleResponse(data);
  if (getResponse === true) {
    history(`/adminRole`);
  }
}

async function getUsersUpdateHistoryData(userId) {

  console.log("userId");
  console.log(userId);
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUsersUpdateHistoryData/${userId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function changeUserManagementStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}accessManagement/assignGroupToUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function assignStudyManagementGroupToUser(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}assignStudyManagementGroupToUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function unAassignStudyManagementGroupToUser(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}unAassignStudyManagementGroupToUser`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
}


async function getManagentList() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}accessManagement/getGroups`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function exportuserDataInCSVFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllUserAndClientDataEXCEL/user/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportuserDataInExcelFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllUserAndClientDataEXCEL/user/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportActiveuserDataInCSVFormat() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllActiveApprovedUserAndClientInCSV/user`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportActiveuserDataInExcelFormat() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportAllActiveApprovedUserAndClientInEXCEL/user`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportRequestuserDataInCSVFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportRequestUserAndClientDataCSV/user/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportRequestuserDataInExcelFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportRequestUserAndClientDataExcel/user/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportArchiveRequestuserDataInCSVFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportArchiveRequestUserAndClientDataCSV/user/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function exportArchiveRequestuserDataInExcelFormat(Status) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportArchiveRequestUserAndClientDataExcel/user/${Status}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}
async function getCountries() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCountries`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getStates(CountryID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStates/${CountryID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
async function getCities(StateID) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getCities/${StateID}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAssignedActiveApprovedClients() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAssignedActiveApprovedClients`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjectIdBasedonClient(clientId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectIdBasedonClient/${clientId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyIdBasedonClient(projectId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyIdBasedonClient/${projectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjectIdBasedonStudyId(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectIdBasedonStudyId/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyRoleBasedonStudyId(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyRoleBasedonStudyId/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function finadStudyLabel(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}finadStudyLabel/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function inviteClient(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}inviteClient`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function inviteUser(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}inviteUser`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function resendInvitation(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}resendInvitation`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function cancelInvitation(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}cancelInvitation`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function updateRequestInvitationStatus(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateRequestInvitationStatus`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function deleteInvitation(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteInvitation/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
}

async function getInvitation(roleType) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getInvitation/${roleType}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRequestInvitation(roleType, requestStatus) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRequestInvitation/${roleType}/${requestStatus}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getInvitationData(token) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getInvitationData/${token}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTotalRequestGraphData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTotalRequestGraphData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTotalClientDataForSubadmin() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTotalClientDataForSubadmin`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTotalUserClientDataForDevops() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTotalUserClientDataForDevops`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getTotalServerSetupGraphData() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getTotalServerSetupGraphData`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}