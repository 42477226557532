import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  drop1: false,
  drop2: false,
  drop3: false,
  drop4: false,
  drop5: false,
  drop6: false,
};

export const sidebarReducer = createReducer(initialState, {
  addDrop1: (state) => {
    state.drop1 = true;
  },

  removeDrop1: (state) => {
    state.drop1 = false;
  },
  // ............

  addDrop2: (state) => {
    state.drop2 = true;
  },

  removeDrop2: (state) => {
    state.drop2 = false;
  },
  // ............

  addDrop3: (state) => {
    state.drop3 = true;
  },

  removeDrop3: (state) => {
    state.drop3 = false;
  },
  // ............

  addDrop4: (state) => {
    state.drop4 = true;
  },

  removeDrop4: (state) => {
    state.drop4 = false;
  },
  // ............

  addDrop5: (state) => {
    state.drop5 = true;
  },

  removeDrop5: (state) => {
    state.drop5 = false;
  },

  // ............

  addDrop6: (state) => {
    state.drop6 = true;
  },

  removeDrop6: (state) => {
    state.drop6 = false;
  },
  // ............
});
