import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { usersOnboardingService } from "../../Services/usersOnboarding";
import { useNavigate, useParams } from "react-router";
import { userServices } from "../../Services/userServices";
import {
  handleResponse,
} from "../../Helpers/Responses";
const options = [
  { value: " -- Select --", label: " -- Select --" },
  { value: "Option 1", label: "Option 1" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
  { value: "Option 4", label: "Option 4" },
];
const StudyLogin = ({
  selectedItem,
  selectStudyLoginType,
  setSelectStudyLoginType,
  assignStudyPermission,
  setAssignStudyPermission,
  DBPCheck,
  DVCheck,
  QCCheck,
  SDVLiveCheck,
  SDVQCCheck,
  LiveCheck,
}) => {
  const { studyId } = useParams();
  const [selectedOptionHistory, setSelectedOptionHistory] = useState(null);
  const [selectedOptionClient, setSelectedOptionClient] = useState(null);
  const [selectedOptionProject, setSelectedOptionProject] = useState(null);
  const [selectedOptionStudy, setSelectedOptionStudy] = useState(null);
  const [selectedOptionRole, setSelectedOptionRole] = useState(null);
  const [selectedOptionEnvironment, setSelectedOptionEnvironment] = useState(null);

  const [clientLists, setClientLists] = useState([]);
  const [projectLists, setProjectLists] = useState([]);
  const [studyLists, setStudyLists] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [projectIdLabel, setProjectIdLabel] = useState("");
  const [studyLabel, setstudyLabel] = useState("");
  const [clientName, setClientName] = useState("");
  const [studyRole, setStudyRole] = useState(null);

  const [studyLoginType, setStudyLoginType] = useState("");

  const [studyPermissionData, setStudyPermissionData] = useState([]);
  const [DBPPermission, setDBPPermission] = useState([]);
  const [DVPermission, setDVPermission] = useState([]);
  const [LivePermission, setLivePermission] = useState([]);
  const [QCPermission, setQCPermission] = useState([]);
  const [SDVLivePermission, setSDVLivePermission] = useState([]);
  const [SDVQCPermission, setSDVQCPermission] = useState([]);

  const [DBPSwitchCheck, setDBPSwitchCheck] = useState("");
  const [DVSwitchCheck, setDVSwitchCheck] = useState("");
  const [LiveSwitchCheck, setLiveSwitchCheck] = useState("");
  const [QCSwitchCheck, setQCSwitchCheck] = useState("");
  const [SDVLiveSwitchCheck, setSDVLiveSwitchCheck] = useState("");
  const [SDVQCSwitchCheck, setSDVQCSwitchCheck] = useState("");

  const viewSelectedItemWithSign = async (projectId) => {
    let permissionData = await userServices.getStudyUserPermission(studyId, projectId)
    setStudyPermissionData(permissionData?.permission)
    setDBPPermission(permissionData?.DBPPermission)
    setDVPermission(permissionData?.DVPermission)
    setLivePermission(permissionData?.LivePermission)
    setQCPermission(permissionData?.QCPermission)
    setSDVLivePermission(permissionData?.SDVLivePermission)
    setSDVQCPermission(permissionData?.SDVQCPermission)
    setDBPSwitchCheck(permissionData?.DBPCheck)
    setDVSwitchCheck(permissionData?.DVCheck)
    setLiveSwitchCheck(permissionData?.LiveCheck)
    setQCSwitchCheck(permissionData?.QCCheck)
    setSDVLiveSwitchCheck(permissionData?.SDVLiveCheck)
    setSDVQCSwitchCheck(permissionData?.SDVQCCheck)
  }

  const getStudyUserPermission = async () => {
    let permission = await userServices.getStudyUserPermission(selectedItem?.study_id, selectedItem?.project_id);
    setStudyRole(permission.permissionRole);
    setStudyPermissionData(permission?.permission)
    setDBPPermission(permission?.DBPPermission)
    setDVPermission(permission?.DVPermission)
    setLivePermission(permission?.LivePermission)
    setQCPermission(permission?.QCPermission)
    setSDVLivePermission(permission?.SDVLivePermission)
    setSDVQCPermission(permission?.SDVQCPermission)
    setDBPSwitchCheck(permission?.DBPCheck)
    setDVSwitchCheck(permission?.DVCheck)
    setLiveSwitchCheck(permission?.LiveCheck)
    setQCSwitchCheck(permission?.QCCheck)
    setSDVLiveSwitchCheck(permission?.SDVLiveCheck)
    setSDVQCSwitchCheck(permission?.SDVQCCheck)
  };

  let studyid = selectedOptionStudy?.value

  let getAssignedActiveApprovedClients = async () => {
    let data = await usersOnboardingService.getAssignedActiveApprovedClients();
    setClientLists(data?.data)
  }

  let getProjectIdBasedonClient = async (clientId) => {
    let data = await usersOnboardingService.getProjectIdBasedonClient(clientId);
    setProjectLists(data?.data)
  }

  let getStudyIdBasedonClient = async (projectId) => {
    let data = await usersOnboardingService.getStudyIdBasedonClient(projectId);
    setStudyLists(data?.data)
  }

  let getStudyRoleBasedonStudyId = async (studyId) => {
    let data = await usersOnboardingService.getStudyRoleBasedonStudyId(studyId);
    setStudyRole(data?.data)
  }

  let getProjectIdBasedonStudyId = async () => {
    let data = await usersOnboardingService.getProjectIdBasedonStudyId(studyId || selectedItem?.study_id);
    setProjectId(data?.projectId)
    setProjectIdLabel(data?.projectLabel)
    setstudyLabel(data?.studyLabel)
    viewSelectedItemWithSign(data?.projectId)
    setClientName(data?.clientName)
  }

  const handleChangeHistory = (selectedOption) => {
    setSelectedOptionHistory(selectedOption);
  };

  const handleChangeClient = (selectedOption) => {
    setSelectedOptionClient(selectedOption);
    getProjectIdBasedonClient(selectedOption?.value);
  };

  const handleChangeProject = (selectedOption) => {
    setSelectedOptionProject(selectedOption);
    getStudyIdBasedonClient(selectedOption?.value)
  };
  const handleChangeStudy = (selectedOption) => {
    setSelectedOptionStudy(selectedOption);
    getStudyRoleBasedonStudyId(selectedOption?.value)
  };
  const handleChangeRole = (selectedOption) => {
    setSelectedOptionRole(selectedOption);
  };

  const role = JSON.parse(localStorage.getItem("role"));

  const navigate = useNavigate();

  const loginStudyModule = () => {
    if (studyRole) {
      navigate(`/${selectStudyLoginType || studyLoginType}/${studyid}`);
      window.location.reload();
      localStorage.setItem("StudyMode", JSON.stringify(
        assignStudyPermission === "DBP" ? "DBP MODE"
          : assignStudyPermission === "DV" ? "DV Mode"
            : assignStudyPermission === "LIVE" ? "Live Mode"
              : assignStudyPermission === "QC" ? "QC Mode"
                : assignStudyPermission === "SDVLIVE" ? "SDV Live Mode"
                  : assignStudyPermission === "SDVQC" ? "SDV QC Mode" : "No Mode"
      ));
      localStorage.setItem("studyPermissionData", JSON.stringify(
        assignStudyPermission === "DBP" ? DBPPermission[0] :
          assignStudyPermission === "DV" ? DVPermission[0] :
            assignStudyPermission === "LIVE" ? LivePermission[0] :
              assignStudyPermission === "QC" ? QCPermission[0] :
                assignStudyPermission === "SDVLIVE" ? SDVLivePermission[0] :
                  assignStudyPermission === "SDVQC" ? SDVQCPermission[0] : ""
      ));
      localStorage.setItem("studyManagement", JSON.stringify(studyRole));
    } else {
      let response = {
        statusCode: 400,
        customMessage: "You have not login, Because you have study role not assign",
      }
      handleResponse(response)
    }
  };

  useEffect(() => {
    getStudyUserPermission();
    getAssignedActiveApprovedClients();
    getProjectIdBasedonStudyId();
  }, [studyId || selectedItem?.study_id, selectedItem?.project_id])
  return (
    <>
      {role !== "study" ?
        <>
          <div className="d-flex align-items-center mb-2" style={{ gap: "20px" }}>
            <p className="m-0"> Select Application</p>
            <Form>
              {["radio"].map((type) => (
                <div className="d-flex align-items-center" key={`inline-${type}`}>
                  <Form.Check
                    className="d-flex align-items-center"
                    inline
                    onChange={(e) => setSelectStudyLoginType(e.target.value)}
                    value={"studyId"}
                    label="EDC"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    className="d-flex align-items-center"
                    inline
                    onChange={(e) => setSelectStudyLoginType(e.target.value)}
                    value={"dataView"}
                    label="Data View"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </div>
              ))}
            </Form>
          </div>
          <Row className="border-top border-bottom p-0 py-2">
            <Col md={12}>
              <div>
                <Form.Label className="m-0">History</Form.Label>
                <Select
                  value={selectedOptionHistory}
                  onChange={handleChangeHistory}
                  options={options}
                  isSearchable={true}
                  placeholder="Search..."
                />
              </div>
            </Col>
          </Row>
        </>
        : ""}
      <Row className="mt-2">
        {role === "study" ?
          <div className="d-flex align-items-center mb-2" style={{ gap: "20px" }}>
            <p className="m-0"> Select Application</p>
            <Form>
              {["radio"].map((type) => (
                <div className="d-flex align-items-center" key={`inline-${type}`}>
                  <Form.Check
                    className="d-flex align-items-center"
                    inline
                    onChange={(e) => setStudyLoginType(e.target.value)}
                    value={"studyId"}
                    label="EDC"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    className="d-flex align-items-center"
                    inline
                    onChange={(e) => setStudyLoginType(e.target.value)}
                    value={"dataView"}
                    label="Data View"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />
                </div>
              ))}
            </Form>
          </div>
          : ""}
        <Col md={6}>
          <div>
            <Form.Label className="m-0">Client</Form.Label>
            <Select
              value={selectedOptionClient}
              onChange={handleChangeClient}
              options={clientLists}
              isSearchable={true}
              placeholder={clientName}
            />
          </div>
        </Col>
        <Col md={6}>
          <div>
            <Form.Label className="m-0">Project ID</Form.Label>
            <Select
              value={selectedOptionProject}
              onChange={handleChangeProject}
              options={projectLists}
              isDisabled={projectLists.length === 0 ? true : false}
              isSearchable={true}
              placeholder={projectIdLabel}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <div>
            <Form.Label className="m-0">Study Id</Form.Label>
            <Select
              value={selectedOptionStudy}
              onChange={handleChangeStudy}
              options={studyLists}
              isSearchable={true}
              isDisabled={studyLists.length === 0 ? true : false}
              placeholder={studyLabel || selectedItem?.study_id}
            />
          </div>
        </Col>
        <Col md={6}>
          <div>
            <Form.Label className="m-0">Role</Form.Label>
            <Select
              value={selectedOptionRole}
              onChange={handleChangeRole}
              options={options}
              isDisabled
              isSearchable={true}
              placeholder={studyRole || "Not Assign"}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12}>
          <div>
            <Form.Label className="m-0">Environment</Form.Label>
            <select
              className="form-select environmentinput lockclientname"
              id="autoSizingSelect"
              onChange={(e) => setAssignStudyPermission(e.target.value)}
            >
              <option>Select Environment</option>
              {DBPCheck || DBPSwitchCheck ?
                <option value={"DBP"}>DBP Mode</option>
                : ""}
              {DVCheck || DVSwitchCheck ?
                <option value={"DV"}>DV Mode</option>
                : ""}
              {LiveCheck || LiveSwitchCheck ?
                <option value={"LIVE"}>Live Mode</option>
                : ""}
              {QCCheck || QCSwitchCheck ?
                <option value={"QC"}>QC Mode</option>
                : ""}
              {SDVLiveCheck || SDVLiveSwitchCheck ?
                <option value={"SDVLIVE"}>SDV Live Mode</option>
                : ""}
              {SDVQCCheck || SDVQCSwitchCheck ?
                <option value={"SDVQC"}>SDV QC Mode</option>
                : ""}
            </select>
          </div>
        </Col>
      </Row>
      {role === "study" ?
        <Button
          className="btn submitbutton me-2 mt-3"
          onClick={() => loginStudyModule()}
          disabled={studyRole && assignStudyPermission ? false : true}
        >
          Login
        </Button>
        : ""}
    </>
  );
};

export default StudyLogin;
