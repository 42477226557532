import React, { useEffect, useRef, useState } from "react";
import UseToggle from "../../Hooks/UseToggle";
import avataricon from "../image/newuser/avataricon.png";
import ChangePassword from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";
import TwoFactorAuth from "./TwoFactorAuth/TwoFactorAuth";
import { userServices } from "../../Services/userServices";

const ProfileActions = ({ ProfileImage, firstName, lastName }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const [authType, setAuthType] = useState("");
  const [authTypeData, setAuthTypeData] = useState("");
  const [status, setStatus] = useState(false);
  const [appTwoFA, setAppTwoFA] = useState(false);
  const [emailTwoFA, setEmailTwoFA] = useState(false);

  const AppsRef = useRef(null);
  const toggleApps = () => setAppsActive(!AppsActive);
  const [AppsActive, setAppsActive] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const getTwoFactorAuthType = async () => {
    let data = await userServices.getTwoFactorAuthType();
    setAuthType(data?.authType);
    setAuthTypeData(data?.authType);
    setStatus(data?.status);
    setAppTwoFA(data?.appTwoFA);
    setEmailTwoFA(data?.emailTwoFA);
  };

  const openTwoFactorAuth = () => {
    getTwoFactorAuthType();
    setToggle2();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    getTwoFactorAuthType();
  }, []);

  return (
    <>
      <div
        ref={AppsRef}
        className={`Apps position-relative ${AppsActive ? "active" : ""}`}
      >
        <div className="icon_wrap hstack gap-1 py-1" onClick={toggleApps} style={{marginRight: 5}}>
          <div
            className={`title_name hstack gap-1 px-2 py-1 ${
              AppsActive ? "AppsNameActive" : ""
            }`}
            style={{ cursor: "pointer" }}
          >
            <div className="hstack gap-1">
              <i className="fa-solid fa-user-tie me-1 text-info"></i>
              <p
                className="m-0"
                style={{ fontSize: 13 }}
              >{`${firstName} ${lastName}`}</p>
              <i class={`fa-solid fa-angle-${AppsActive ? "up" : "down"}`}></i>
            </div>
          </div>
        </div>
        {/* <div className="nav-item dropdown gaurav">
        <span
          className="nav-link dropdown-toggle navdropdown pe-2 CP"
          id="navbarDropdown1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src={avataricon}
            className="profileImage rounded-circle mx-2"
            height="20"
            width="20"
            alt=""
          />

          {`${firstName} ${lastName}`}
        </span> */}

        <div className="Apps_dd shadow">
          <ul className="Apps_ul">
            <li>
              <a
                onClick={setToggle}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <i className="fa-solid fa-address-card me-2"></i>Profile
              </a>
            </li>
            <li>
              <a
                onClick={setToggle1}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <i className="fa-solid fa-unlock-keyhole me-2"></i> Change
                Password
              </a>
            </li>
            <li>
              {authTypeData && (
                <a
                  onClick={openTwoFactorAuth}
                  className="dropdown-item"
                  style={{ fontSize: 12, cursor: "pointer" }}
                >
                  <i className="fa-solid fa-shield-halved me-2"></i>Two Factor
                  Auth
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>

      <UpdateProfile SHOW={!toggle} HIDE={setToggle} TITLE={"User Profile"} />

      <ChangePassword
        SHOW={!toggle1}
        HIDE={setToggle1}
        TITLE={"Change Password"}
      />

      <TwoFactorAuth
        SHOW={!toggle2}
        HIDE={setToggle2}
        TITLE={"Two Factor Auth"}
        getTwoFactorAuthType={getTwoFactorAuthType}
        setAuthType={setAuthType}
        authType={authType}
        authTypeData={authTypeData}
        status={status}
        appTwoFA={appTwoFA}
        emailTwoFA={emailTwoFA}
      />
    </>
  );
};

export default ProfileActions;
