import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import StudyLogin from "../Login/StudyLogin";
const { Toggle, Menu, Item } = Dropdown;

const SwitchAccess = () => {
  const previousRole = JSON.parse(localStorage.getItem("previousRole"));

  const loginMainDashboard = () => {
    if (previousRole === "client") {
      window.location.href = `/clientRole`;
    } else {
      window.location.href = `/userRole`;
    }
    localStorage.setItem("role", JSON.stringify(previousRole));
    localStorage.removeItem("previousRole");
    localStorage.removeItem("StudyMode");
    localStorage.removeItem("studyPermissionData");
    localStorage.removeItem("studyStatus");
  };

  const [show, setShow] = useState(false);
  const [assignStudyPermission, setAssignStudyPermission] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <Dropdown>
        <Toggle className="white bell" variant="none">
          Switch
        </Toggle>

        <Menu className="toolsDrops">
          <Item onClick={loginMainDashboard}>{"Switch to Main Dashboard"}</Item>
          <Item onClick={handleShow}>{"Switch to Study"}</Item>
        </Menu>
      </Dropdown>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">Welcome to Galax</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudyLogin
            assignStudyPermission={assignStudyPermission}
            setAssignStudyPermission={setAssignStudyPermission}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SwitchAccess;
