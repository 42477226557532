import React, { useCallback, useEffect, useState } from "react";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import ArchiveLists from "./AdminRoleLeft/ArchiveLists";
import { DropdownArrowRightIcon } from "../../Assets/Icons/GlobalIcons";
import { Form, Modal } from "react-bootstrap";
import { clientsOnboardingService } from "../../Services/clientsOnboarding";
import InboxLists from "./AdminRoleLeft/InboxLists";
import InvitationInbox from "./AdminRoleLeft/InvitationInbox";
import ETMFList from "./AdminRoleLeft/ETMFList";
import { globalFormServices } from "../../Services/formServices/globalFormServices";
import { etmfReferenceModalServices } from "../../Services/studyServices/etmfReferenceModalServices";
import { CollapseBody, CollapseButton } from "../Layouts/Layouts";

const LeftComponent11 = ({
  roleType,
  ActiveClients,
  ApprovedClients,
  InactiveClients,
  FreezedClients,
  OnBoardingClients,
  ArchivedClients,
  ActiveUsers,
  InactiveUsers,
  FreezedUsers,
  OnBoardingUsers,
  ArchivedUsers,
  ApprovedUsers,
  RequestPendingClients,
  RequestRejectedClients,
  RequestApprovedClients,
  RequestPendingUsers,
  RequestRejectedUsers,
  RequestApprovedUsers,
  ArchivePendingClients,
  ArchiveRejectedClients,
  ArchiveApprovedClients,
  ArchivePendingUsers,
  ArchiveRejectedUsers,
  ArchiveApprovedUsers,
  CountOfRoleStatus,
  TotalRequestCount,
  ChildResponce,
  CountOfRequestWithinDays,
  user,
  countOfClientInvitation,
  approvedClientInvitation,
  pendingClientInvitation,
  rejectedClientInvitation,
  countOfUserInvitation,
  approvedUserInvitation,
  pendingUserInvitation,
  rejectedUserInvitation,
  activeDevops,
  inactiveDevops,
  onBoardDevops,
  setModalId,
  modalId,
  setModalName,
  handleAddNewTab,
  checkActiveTab,
}) => {
  const {
    drop1State,
    drop2State,
    drop3State,
    drop4State,
    drop5State,
    drop6State,
  } = UseSidebarReducer();

  const [referenceModal, setReferenceModal] = useState("");
  const [result, setResult] = useState([]);
  const [clientLists, setClientLists] = useState([]);
  const [referenceModalList, setReferenceModalList] = useState([]);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const getGlobalFormFolderName = useCallback(async () => {
    let data;
    if (roleType === "adminRole") {
      data = await globalFormServices.getGlobalFormFolderName();
    } else {
      data = await globalFormServices.getVisibleGlobalFormFolderName();
    }
    setResult(data?.data);
  }, [roleType]);

  const getAllClients = useCallback(async () => {
    if (roleType === "clientRole") {
      let data = await clientsOnboardingService.getAllClients();
      setClientLists(data);
    } else {
      let data =
        await clientsOnboardingService.getActiveApprovedClientsForValidator();
      setClientLists(data.data);
    }
  }, [roleType]);

  const getReferenceModals = async (e) => {
    let data = await etmfReferenceModalServices.getReferenceModals();
    setReferenceModalList(data?.data);
  };

  const addNewReferenceModal = async () => {
    let userData = {};
    userData.name = referenceModal;
    let data = await etmfReferenceModalServices.addNewReferenceModal(userData);
    if (data?.statusCode === 200) {
      handleClose3();
      setReferenceModal("");
      getReferenceModals();
    }
  };

  useEffect(() => {
    getAllClients();
    CountOfRoleStatus();
    getGlobalFormFolderName();
    TotalRequestCount();
  }, [getAllClients, getGlobalFormFolderName]);

  let permissionData = JSON.parse(localStorage.getItem("permissionData"));
  return (
    <>
      <div className="sidebar" id="myGroup">
        <div className="menus">
          <ul>
            {drop1State && (
              <>
                <li className="hstack justify-content-between w-100 border-bottom bg-body-secondary">
                  <div className="hstack justify-content-between w-100">
                    <a
                      className="hstack justify-content-between gap-2"
                      aria-controls="clients"
                      onClick={(e) => CountOfRoleStatus(e)}
                    >
                      <i className="fa-solid fa-users users ms-2"></i>
                      Clients
                    </a>
                    {!user && (
                      <span
                        title="Add Client"
                        onClick={() => {
                          handleAddNewTab(
                            "Add New Client",
                            "fa-solid fa-circle-plus",
                            "addNewClient"
                          );
                        }}
                      >
                        <i className="fa-solid fa-circle-plus"></i>
                      </span>
                    )}
                  </div>
                </li>
                <div
                  className="w-100"
                  style={{ height: "calc(100vh - 156px)", overflowX: "hidden" }}
                >
                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("activeClients") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Active Clients",
                        "fa-solid fa-address-card",
                        "activeClients"
                      );
                    }}
                  >
                    <i className="fa-solid fa-address-card activeclient mx-2"></i>
                    Active Clients ({ActiveClients + ApprovedClients})
                  </span>

                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("inactiveClients") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Inactive Clients",
                        "fa-solid fa-address-card",
                        "inactiveClients"
                      );
                    }}
                  >
                    <i className="fa-solid fa-address-card inactiveclient mx-2"></i>
                    Inactive Clients ({InactiveClients})
                  </span>

                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("archivedClients") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Archived Clients",
                        "fa-solid fa-address-card",
                        "archivedClients"
                      );
                    }}
                  >
                    <i className="fa-solid fa-address-card archievedusers mx-2"></i>
                    Archived Clients ({ArchivedClients})
                  </span>

                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("frozenClients") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Frozen Clients",
                        "fa-solid fa-address-card",
                        "frozenClients"
                      );
                    }}
                  >
                    <i className="fa-solid fa-address-card frozenusers mx-2"></i>
                    Frozen Clients ({FreezedClients})
                  </span>

                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("onBoardingClients") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "OnBoarding Clients",
                        "fa-solid fa-address-card",
                        "onBoardingClients"
                      );
                    }}
                  >
                    <i className="fa-solid fa-address-card onBoardusers mx-2"></i>
                    OnBoarding Clients ({OnBoardingClients})
                  </span>
                </div>
              </>
            )}

            {drop2State && (
              <>
                <li className="hstack justify-content-between w-100 border-bottom bg-light">
                  <a
                    data-bs-toggle="collapse show"
                    aria-expanded="true"
                    aria-controls="collapseExample3"
                    className="w-100"
                  >
                    <div className="hstack justify-content-between w-100 px-2">
                      <div className="hstack gap-2">
                        <i className="fa-solid fa-envelope users"></i>
                        <p className="m-0">Request Management</p>
                      </div>
                    </div>
                  </a>
                </li>
              </>
            )}

            {drop3State && (
              <>
                <li className="hstack justify-content-between CP w-100 border-bottom bg-body-secondary">
                  <div className="hstack justify-content-between w-100">
                    <a className="hstack justify-content-between gap-2">
                      <i className="fa-solid fa-users-gear users ms-2"></i>
                      Users
                    </a>
                    <span
                      title="Add User"
                      onClick={() => {
                        handleAddNewTab(
                          "Add New User",
                          "fa-solid fa-circle-plus",
                          "addNewUser"
                        );
                      }}
                    >
                      <i className="fa-solid fa-circle-plus"></i>
                    </span>
                  </div>
                </li>
                <div
                  className="w-100"
                  style={{ height: "calc(100vh - 156px)", overflowX: "hidden" }}
                >
                  <li className="hstack justify-content-between CP w-100 border-bottom">
                    <CollapseButton
                      Href="#activeUsers"
                      OnClick={(e) => CountOfRoleStatus(e)}
                    >
                      <div className="hstack justify-content-between w-100 px-0 ps-3">
                        <div className="hstack gap-2">
                          <i className="fa-solid fa-folder users"></i>
                          <p className="m-0 fontSize12">
                            Users Table (
                            {ActiveUsers +
                              ApprovedUsers +
                              InactiveUsers +
                              FreezedUsers +
                              ArchivedUsers +
                              OnBoardingUsers}
                            )
                          </p>
                        </div>
                        <DropdownArrowRightIcon />
                      </div>
                    </CollapseButton>
                  </li>
                  <CollapseBody HrefID="activeUsers">
                    <span
                      className={`CP ps-3 small ${
                        checkActiveTab("ActiveUsers") && "selectedMenu"
                      }`}
                      onClick={() => {
                        handleAddNewTab(
                          "Active Users",
                          "fa-solid fa-users-gear",
                          "ActiveUsers"
                        );
                      }}
                    >
                      <i className="fa-solid fa-users-gear mx-2 activeclient"></i>
                      Active Users ({ActiveUsers + ApprovedUsers})
                    </span>

                    <span
                      className={`CP ps-3 small ${
                        checkActiveTab("InactiveUsers") && "selectedMenu"
                      }`}
                      onClick={() => {
                        handleAddNewTab(
                          "Inactive Users",
                          "fa-solid fa-users-gear",
                          "InactiveUsers"
                        );
                      }}
                    >
                      <i className="fa-solid fa-users-gear mx-2 inactiveclient"></i>
                      Inactive Users ({InactiveUsers})
                    </span>

                    <span
                      className={`CP ps-3 small ${
                        checkActiveTab("ArchivedUsers") && "selectedMenu"
                      }`}
                      onClick={() => {
                        handleAddNewTab(
                          "Archived Users",
                          "fa-solid fa-users-gear",
                          "ArchivedUsers"
                        );
                      }}
                    >
                      <i className="fa-solid fa-users-gear mx-2 archievedusers"></i>
                      Archived Users ({ArchivedUsers})
                    </span>

                    <span
                      className={`CP ps-3 small ${
                        checkActiveTab("FreezedUsers") && "selectedMenu"
                      }`}
                      onClick={() => {
                        handleAddNewTab(
                          "Frozen Users",
                          "fa-solid fa-users-gear",
                          "FreezedUsers"
                        );
                      }}
                    >
                      <i className="fa-solid fa-users-gear mx-2 frozenusers"></i>
                      Frozen Users ({FreezedUsers})
                    </span>

                    <span
                      className={`CP ps-3 small ${
                        checkActiveTab("OnBoardingUsers") && "selectedMenu"
                      }`}
                      onClick={() => {
                        handleAddNewTab(
                          "Onboarding Users",
                          "fa-solid fa-users-gear",
                          "OnBoardingUsers"
                        );
                      }}
                    >
                      <i className="fa-solid fa-users-gear mx-2 onBoardusers"></i>
                      OnBoarding Users ({OnBoardingUsers})
                    </span>
                  </CollapseBody>
                  <li className="hstack justify-content-between w-100 border-bottom">
                    <a
                      data-bs-toggle="collapse"
                      href="#activeDevops"
                      aria-expanded="false"
                      aria-controls="activeDevops"
                      onClick={(e) => {
                        CountOfRoleStatus(e);
                      }}
                      className="w-100"
                    >
                      <div className="hstack justify-content-between w-100 px-0 ps-3">
                        <div className="hstack gap-2">
                          <i className="fa-solid fa-folder users"></i>
                          <p className="m-0 fontSize12">
                            Devops Table (
                            {activeDevops + inactiveDevops + onBoardDevops})
                          </p>
                        </div>
                        <DropdownArrowRightIcon />
                      </div>
                    </a>
                  </li>
                  <div className="collapse client-collapse" id="activeDevops">
                    <div className="card card-body">
                      <span
                        className={`CP ps-3 small ${
                          checkActiveTab("activeDevops") && "selectedMenu"
                        }`}
                        onClick={() => {
                          handleAddNewTab(
                            "Active Devops",
                            "fa-solid fa-users-gear",
                            "activeDevops"
                          );
                        }}
                      >
                        <i class="fa-solid fa-users-gear mx-2 activeclient"></i>
                        Active Devops ({activeDevops})
                      </span>

                      <span
                        className={`CP ps-3 small ${
                          checkActiveTab("inactiveDevops") && "selectedMenu"
                        }`}
                        onClick={() => {
                          handleAddNewTab(
                            "Inactive Devops",
                            "fa-solid fa-users-gear",
                            "inactiveDevops"
                          );
                        }}
                      >
                        <i class="fa-solid fa-users-gear mx-2 inactiveclient"></i>
                        Inactive Devops ({inactiveDevops})
                      </span>

                      {/* <span
                            className="CP ps-3 small"
                            onClick={() => addArchiveDevopsUserTab()}
                          >
                            <i class="fa-solid fa-users-gear mx-2 archievedusers"></i>
                            Archive Devops ({archiveDevops})
                          </span>

                          <span
                            className="CP ps-3 small"
                            onClick={() => addFreezeDevopsUserTab()}
                          >
                            <i class="fa-solid fa-users-gear mx-2 frozenusers"></i>
                            Frozen Devops ({freezeDevops})
                          </span> */}

                      <span
                        className={`CP ps-3 small ${
                          checkActiveTab("onboardingDevops") && "selectedMenu"
                        }`}
                        onClick={() => {
                          handleAddNewTab(
                            "OnBoarding Devops",
                            "fa-solid fa-users-gear",
                            "onboardingDevops"
                          );
                        }}
                      >
                        <i class="fa-solid fa-users-gear mx-2 onBoardusers"></i>
                        OnBoarding Devops ({onBoardDevops})
                      </span>
                    </div>
                  </div>
                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("accessManagement") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Access Management",
                        "fa-solid fa-building-lock",
                        "accessManagement"
                      );
                    }}
                  >
                    <i class="fa-solid fa-building-lock mx-2 clients"></i>
                    Access Management
                  </span>
                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("designationManagement") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Designation",
                        "fa-solid fa-id-card",
                        "designationManagement"
                      );
                    }}
                  >
                    <i class="fa-solid fa-id-card mx-2 clients"></i>
                    Designation
                  </span>
                </div>
              </>
            )}

            {drop5State && (
              <>
                <li className="hstack justify-content-between CP w-100 border-bottom bg-body-secondary">
                  <a className="hstack gap-2 w-100">
                    <i className="fa-solid fa-folder users ms-2"></i>
                    Log History
                  </a>
                </li>
                <div
                  className="w-100"
                  style={{ height: "calc(100vh - 156px)", overflowX: "hidden" }}
                >
                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("logsHistory") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Logs History",
                        "fa-solid fa-clock-rotate-left",
                        "logsHistory"
                      );
                    }}
                  >
                    <i className="fa-solid fa-clock-rotate-left mx-2"></i>
                    Logs History
                  </span>
                  <span
                    className={`CP ps-3 small ${
                      checkActiveTab("failedLogin") && "selectedMenu"
                    }`}
                    onClick={() => {
                      handleAddNewTab(
                        "Failed Login",
                        "fa-solid fa-circle-exclamation",
                        "failedLogin"
                      );
                    }}
                  >
                    <i className="fa-solid fa-circle-exclamation mx-2"></i>
                    Failed Login
                  </span>
                </div>
              </>
            )}

            {drop6State && (
              <li className="hstack justify-content-between w-100 border-bottom">
                <a
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="etmfList"
                  onClick={(e) => getReferenceModals()}
                  className="w-100"
                >
                  <div className="hstack justify-content-between w-100 px-2">
                    <div className="hstack gap-2">
                      <i className="fa-solid fa-folder users"></i>
                      <p className="m-0" style={{ cursor: "pointer" }}>
                        eTMF (Reference Modal)
                      </p>
                    </div>
                  </div>
                </a>
                <div className="hstack gap-2">
                  <span title="Client Invitation">
                    <i
                      onClick={handleShow3}
                      className="CP fa fa-circle-plus users"
                    ></i>
                  </span>
                </div>
              </li>
            )}
          </ul>

          <div className="sidebar-body">
            {/* Request List  */}
            {drop2State && (
              <div
                className="collapse client-collapse show"
                id="collapseExampleRequest"
                data-bs-parent="#myGroup"
              >
                <div className="card card-body">
                  {/* Inbox Lists */}

                  <InboxLists
                    CountOfRequestWithinDays={CountOfRequestWithinDays}
                    RequestPendingUsers={RequestPendingUsers}
                    RequestApprovedUsers={RequestApprovedUsers}
                    RequestRejectedUsers={RequestRejectedUsers}
                    user={user}
                    handleAddNewTab={handleAddNewTab}
                    checkActiveTab={checkActiveTab}
                    permissionData={permissionData}
                    RequestApprovedClients={RequestApprovedClients}
                    RequestPendingClients={RequestPendingClients}
                    RequestRejectedClients={RequestRejectedClients}
                  />
                  {/* Archive Lists */}
                  <li className="hstack justify-content-between w-100">
                    <a
                      data-bs-toggle="collapse"
                      href="#archive"
                      aria-expanded="false"
                      aria-controls="archive"
                      onClick={(e) => CountOfRequestWithinDays(e)}
                      className="w-100"
                    >
                      <div className="hstack justify-content-between w-100 px-1">
                        <div className="hstack gap-2">
                          <i className="fa-solid fa-archive users"></i>
                          <p className="m-0">
                            Archived (
                            {ArchivePendingClients +
                              ArchiveRejectedClients +
                              ArchiveApprovedClients +
                              ArchivePendingUsers +
                              ArchiveRejectedUsers +
                              ArchiveApprovedUsers}
                            )
                          </p>
                        </div>
                        <DropdownArrowRightIcon />
                      </div>
                    </a>
                  </li>
                  <div className="collapse client-collapse" id="archive">
                    <div className="card card-body">
                      <ArchiveLists
                        pendingClients={ArchivePendingClients}
                        rejectedClients={ArchiveRejectedClients}
                        approvedClients={ArchiveApprovedClients}
                        pendingUsers={ArchivePendingUsers}
                        handleAddNewTab={handleAddNewTab}
                        checkActiveTab={checkActiveTab}
                        rejectedUsers={ArchiveRejectedUsers}
                        approvedUsers={ArchiveApprovedUsers}
                        stateChanger={ChildResponce}
                      />
                    </div>
                  </div>
                  {/* Invitation Inbox */}
                  <li className="hstack justify-content-between w-100">
                    <a
                      data-bs-toggle="collapse"
                      href="#invitation"
                      aria-expanded="false"
                      aria-controls="invitation"
                      onClick={(e) => CountOfRequestWithinDays(e)}
                      className="w-100"
                    >
                      <div className="hstack justify-content-between w-100 px-1">
                        <div className="hstack gap-2">
                          <i className="fa-solid fa-envelope-open-text users"></i>
                          <p className="m-0">
                            Invitation Inbox (
                            {approvedClientInvitation +
                              pendingClientInvitation +
                              rejectedClientInvitation +
                              approvedUserInvitation +
                              pendingUserInvitation +
                              rejectedUserInvitation}
                            )
                          </p>
                        </div>
                        <DropdownArrowRightIcon />
                      </div>
                    </a>
                  </li>
                  <div className="collapse client-collapse" id="invitation">
                    <div className="card card-body">
                      <InvitationInbox
                        countOfUserInvitation={countOfUserInvitation}
                        approvedUserInvitation={approvedUserInvitation}
                        rejectedUserInvitation={rejectedUserInvitation}
                        pendingUserInvitation={pendingUserInvitation}
                        handleAddNewTab={handleAddNewTab}
                        checkActiveTab={checkActiveTab}
                        countOfClientInvitation={countOfClientInvitation}
                        approvedClientInvitation={approvedClientInvitation}
                        pendingClientInvitation={pendingClientInvitation}
                        rejectedClientInvitation={rejectedClientInvitation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* ETMF List */}
            {drop6State && (
              <div
                className="collapse client-collapse show"
                id="etmfList"
                data-bs-parent="#myGroup"
              >
                <div className="card card-body">
                  <ETMFList
                    referenceModalList={referenceModalList}
                    referenceModal={referenceModal}
                    setReferenceModal={setReferenceModal}
                    setModalId={setModalId}
                    modalId={modalId}
                    getReferenceModals={getReferenceModals}
                    setModalName={setModalName}
                    handleAddNewTab={handleAddNewTab}
                    checkActiveTab={checkActiveTab}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add New Folder for Global FOrm */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Reference Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Enter Reference Modal"
            className="rounded-1 w-100"
            onChange={(e) => setReferenceModal(e.target.value)}
            value={referenceModal}
            required
          />
        </Modal.Body>
        <Modal.Footer className="p-1">
          <button
            className="border bg-light px-4 py-1 rounded-1 fw-bold"
            onClick={handleClose3}
          >
            Cancel
          </button>
          <button
            className="submitbutton px-4"
            onClick={addNewReferenceModal}
            disabled={!referenceModal}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LeftComponent11;
