import React, { useEffect, useState } from "react";
// import avataricon from "../../../Components/image/newuser/avataricon.png";
import config from "../../../config/config.json";
import Navbar from "../../../Components/Navbar/Navbar";
import "./UserProfile.scss";
import Footer from "../../../Components/Footer/Footer";
import { userServices } from "../../../Services/userServices";
import { usersOnboardingService } from "../../../Services/usersOnboarding";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import ManPic from "../../../img/man.png";


const UserProfile = ({ HIDE }) => {
  const [currentId, setCurrentId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");

  const [shortName, setShortName] = useState("");
  const [website, setWebsite] = useState("");
  const [tier, setTier] = useState("");

  const [userId, setUserId] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // const [getFile, setGetFile] = useState("");
  // const [getFileName, setGetFileName] = useState("");

  const [image, setImage] = useState("");

  const [designation, setDesignation] = useState([]);
  const [management, setManagement] = useState([]);

  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [cityName, setCityName] = useState([]);

  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [stateName, setStateName] = useState([]);

  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [countryName, setCountryname] = useState([]);
  const roleType = JSON.parse(localStorage.getItem("role"));

  const history = useNavigate();

  useEffect(() => {
    getCountries();
    currentDetails();
  }, []);

  const getCountries = async (e) => {
    let data = await usersOnboardingService.getCountries(e);
    setCountryLists(data);
  };

  const getStates = async (e) => {
    let data = await usersOnboardingService.getStates(e);
    setStateLists(data);
  };

  const getCities = async (e) => {
    let data = await usersOnboardingService.getCities(e);
    setCityLists(data);
  };

  const currentDetails = async (e) => {
    let userData = await userServices.getCurrentUserDetails(e);

    setCurrentId(userData?.[0]?.user_id?._id);
    setFirstName(userData?.[0]?.user_id?.firstName);
    setMiddleName(userData?.[0]?.user_id?.middleName);
    setLastName(userData?.[0]?.user_id?.lastName);
    setShortName(userData?.[0]?.user_id?.shortName);
    setWebsite(userData?.[0]?.user_id?.website);
    setTier(userData?.[0]?.user_id?.tier);
    setUserId(userData?.[0]?.user_id?.userId);
    setEmail(userData?.[0]?.user_id?.email);
    setManagement(userData[1]?.management_name.name);
    setDesignation(userData[2]?.designation_name.name);
    setPhone(userData?.[0]?.user_id?.phone);
    setAddress(userData?.[0]?.address);
    setOfficePhone(userData?.[0]?.office_phone);
    setPostalCode(userData?.[0]?.postalCode);

    setImage(userData?.[0]?.user_id?.image);

    setCityID(userData?.[0]?.city_id?._id);
    setStateID(userData?.[0]?.state_id?._id);
    setCountryID(userData?.[0]?.country_id?._id);
    setCityName(userData?.[0]?.city_id?.name);
    setStateName(userData?.[0]?.state_id?.name);
    setCountryname(userData?.[0]?.country_id?.name);
  };

  const handleSubmit = async (e) => {
    // const formData = new FormData();
    // formData.append("uploadImage", getFile, getFileName);

    let userData = {};
    userData.id = currentId;
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.shortName = shortName;
    userData.website = website;
    userData.phone = phone;
    userData.address = address;
    userData.office_phone = officePhone;
    userData.country_id = country_id;
    userData.state_id = state_id;
    userData.city_id = city_id;
    userData.postalCode = postalCode;
    // userData.image = formData;

    await usersOnboardingService.updateUserByID(userData, history);
    localStorage.setItem("firstName", JSON.stringify(firstName));
    localStorage.setItem("lastName", JSON.stringify(lastName));
    HIDE()
  };

  const getImageDetails = async (e) => {
    // setGetFile(e.target.files[0]);
    // setGetFileName(e.target.files[0].name);
  };

  return (
    <>
      {/* <Navbar Roles="" /> */}

      {/* <section className="profileSection" id="dashboard-section">
        <div className="inner-cont ms-1">
          <div className="profile-section">
            <h1 className="text-center py-2 w-100 border mb-3 title">
              {firstName} Profile
            </h1> */}
      <div className="Profile_img ">
        <Row>
          <Col md={4} className="mb-3">
            <div className="Profile_img ">
              <Form.Label>Profile Image</Form.Label>
            </div>
            <div
              className="bg-white shadow-sm border-radius-3"
              style={{ padding: "21px 10px" }}
            >
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  // onChange={handleImageChange}
                  />
                  <label htmlFor="imageUpload" title="Upload Profile Pic"></label>
                </div>
                <div className="avatar-preview">
                  {ManPic ? (
                    <img src={ManPic} alt="Preview" />
                  ) : (
                    <img src={ManPic} alt="Default Preview" />
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <Row>
              <Form.Group as={Col} md="8" className="mb-3">
                <Form.Label>
                  Display Name (name will appear to other users){" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control"
                  id="inputUsername"
                  type="text"
                  placeholder={
                    roleType === "user"
                      ? "Enter First Name"
                      : roleType === "super_user"
                        ? "Enter First Name"
                        : roleType === "client"
                          ? "Enter Full Name"
                          : "Enter First Name"
                  }
                  value={firstName + " " + lastName}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="mb-3">
                <Form.Label>
                  {roleType === "user"
                    ? "First Name"
                    : roleType === "super_user"
                      ? "First Name"
                      : roleType === "client"
                        ? "Full Name"
                        : "First Name"}{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder={
                    roleType === "user"
                      ? "Enter First Name"
                      : roleType === "super_user"
                        ? "Enter First Name"
                        : roleType === "client"
                          ? "Enter Full Name"
                          : "Enter First Name"
                  }
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </Form.Group>
              {roleType === "client" ? (
                ""
              ) : (
                <Form.Group as={Col} md="6" className="mb-3">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setMiddleName(e.target.value)}
                    value={middleName}
                    placeholder="Enter Middle Name"
                  />
                </Form.Group>
              )}

              {roleType === "client" ? (
                ""
              ) : (
                <Form.Group as={Col} md="6" className="mb-3">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your last name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </Form.Group>
              )}

              {roleType === "client" ? (
                <Form.Group as={Col} md="6" className="mb-3">
                  <Form.Label>Short name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Short Name"
                    onChange={(e) => setShortName(e.target.value)}
                    value={shortName}
                  />
                </Form.Group>
              ) : (
                ""
              )}

              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Website"
                  onChange={(e) => setWebsite(e.target.value)}
                  value={website}
                />
              </Form.Group>
            </Row>
          </Col>

          <Form.Group as={Col} md="12" className="mb-3">
            <Form.Label>
              Address<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter your Address"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>
              Country <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setCountryID(e.target.value)}
              onClick={(e) =>
                getStates(e.target[e.target.selectedIndex].title)
              }
            >
              <option selected disabled>
                {countryName}
              </option>
              {countryLists?.length > 0
                ? countryLists?.map((item) => (
                  <option
                    key={item._id}
                    selected={state_id === item._id ? true : false}
                    value={item._id}
                    title={item.id}
                  >
                    {item.name}
                  </option>
                ))
                : ""}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>
              State <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              onChange={(e) => setStateID(e.target.value)}
              onClick={(e) =>
                getCities(e.target[e.target.selectedIndex].title)
              }
            >
              <option selected disabled>
                {stateName}
              </option>
              {stateLists?.length > 0
                ? stateLists?.map((item) => (
                  <option key={item._id} value={item._id} title={item.id}>
                    {item.name}
                  </option>
                ))
                : ""}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" className="mb-3">
            <Form.Label>
              City <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setCityID(e.target.value)}>
              <option selected disabled>
                {cityName}
              </option>
              {cityLists?.length > 0
                ? cityLists?.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))
                : ""}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Postal Code"
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="+91-52845-75316"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Office Phone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="+91-080-411-22887"
              onChange={(e) => setOfficePhone(e.target.value)}
              value={officePhone}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>userId</Form.Label>
            <Form.Control
              type="text"
              placeholder="userId"
              value={userId}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              value={email}
              disabled
            />
          </Form.Group>

          {roleType === "client" ? (
            ""
          ) : roleType === "super_user" ? (
            ""
          ) : (
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Designation"
                value={designation}
                disabled
              />
            </Form.Group>
          )}

          {roleType === "client" ? (
            ""
          ) : roleType === "super_user" ? (
            ""
          ) : (
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>Management Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Management Group"
                value={management}
                disabled
              />
            </Form.Group>
          )}

          {roleType === "client" && (
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>Tier</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter tier"
                value={tier}
                disabled
              />
            </Form.Group>
          )}

          <div className="text-end">
            <button
              onClick={(e) => handleSubmit()}
              className="updateProfile"
              type="button"
            >
              Save changes
            </button>
          </div>
        </Row>
      </div>
      {/* </div>
        </div>
      </section> */}

      {/* <Footer /> */}
    </>
  );
};

export default UserProfile;
