import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const etmfReferenceModalServices = {
  addNewReferenceModal,
  updateReferenceModal,
  getReferenceModals,
  deleteReferenceModalData,
  updateStatusFolderAndModal,
  
  addNewFolder,
  updateFolder,
  updateFile,
  getFolderTree,
  deleteFolderData,
  deleteFileData,
};
// Add Reference Modal
async function addNewReferenceModal(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewReferenceModal`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Update Reference Modal
async function updateReferenceModal(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateReferenceModal`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Update Folder &b Reference Modal
async function updateStatusFolderAndModal(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateStatusFolderAndModal`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Get Reference Modal
async function getReferenceModals() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getReferenceModals`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// Delete Reference Modal
async function deleteReferenceModalData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteReferenceModalData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Folder
async function addNewFolder(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addNewFolder`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Update Folder
async function updateFolder(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateFolder`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Update File
async function updateFile(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateFile`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Get Folder
async function getFolderTree(modalId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getFolderTree/${modalId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
// Delete Folder
async function deleteFolderData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteFolderData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Delete File
async function deleteFileData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteFileData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}