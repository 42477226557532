import React from "react";
import ReactApexChart from "react-apexcharts";

const GradientDonutAdminInvitationChart = ({invitationGraph}) => {
  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      position: "bottom",
    },
    title: {
      text: "Invitation",
    },
    labels: ["User Invitation", "Client Invitation"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <div id="chart" className="chart_design_port">
      <ReactApexChart
        options={options}
        series={invitationGraph}
        type="donut"
        width={360}
      />
    </div>
  );
};

export default GradientDonutAdminInvitationChart;
