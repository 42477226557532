import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ListGroup, Form } from 'react-bootstrap';
import { HiMiniXMark } from 'react-icons/hi2';
import TimeDuration from '../../../utils/universalFunction';

const ItemType = 'TAB';

const DraggableDropdownTab = ({ item, index, handleMoveTab, handleActiveTab, handleDeleteTab }) => {
    // const ref = useRef(null);

    // const [, drop] = useDrop({
    //     accept: ItemType,
    //     hover: (draggedItem) => {
    //         if (draggedItem.index !== index) {
    //             handleMoveTab(draggedItem.index, index);
    //             draggedItem.index = index;
    //         }
    //     },
    // });

    // const [{ isDragging }, drag] = useDrag({
    //     type: ItemType,
    //     item: { index },
    //     collect: (monitor) => ({
    //         isDragging: monitor.isDragging(),
    //     }),
    // });

    // drag(drop(ref));

    return (
        <ListGroup.Item
            // ref={ref}
            key={item?.edcTabId}
            action
            className={`py-1 px-2 ps-3 ${item?.status === 'active' ? 'activeMenu' : ''}`}
            // style={{ opacity: isDragging ? 1 : 1, transition: 'opacity 0.2s ease' }}
        >
            <div className="hstack justify-content-between">
                <Form.Label
                    onClick={() => handleActiveTab(item?.edcTabId)}
                    className="m-0 d-flex cursor-pointer text-truncate w-100"
                >
                    <i className={`${item?.icon} text-muted me-2 pt-1`}></i>
                    <div className="d-block" style={{ lineHeight: '18px' }}>
                        <div className='text-truncate'>{item?.edcTabName}</div>
                        <Form.Text className="fs-10">
                            <TimeDuration DateTime={item?.openDT} />
                        </Form.Text>
                    </div>
                </Form.Label>
                <HiMiniXMark
                    onClick={() => handleDeleteTab(item?.edcTabId)}
                    title="Close Tab"
                />
            </div>
        </ListGroup.Item>
    );
};

export default DraggableDropdownTab;
